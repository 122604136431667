import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Bench Press 3×12`}</p>
    <p>{`DB Rows 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`10 Rope Climbs to 15′`}</p>
    <p>{`30 Calorie Bike`}</p>
    <p>{`50 V Ups`}</p>
    <p>{`70 Pushups`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`*`}{`To***`}</strong>{`day’s 6pm class will be cancelled so that we can set up for
our Granite Games Throwdown.  Any help with setting up would be greatly
appreciated!***`}</p>
    <p><strong parentName="p">{`*`}{`Today from 3-? Bluegrass Bodyfat Testing will be set up to do body
fat tests for just \\$30 (\\$25 for GG competitors)!  This is a \\$15-\\$20
savings off their normal prices so take advantage of this deal!  You
will get wet so bring swimwear or workout clothes and a
towel. `}<a parentName="strong" {...{
          "href": "http://www.bluegrassbftest.com"
        }}>{`http://www.bluegrassbftest.com`}</a>{`. They will be set up for
Saturday’s competition as well if you can’t make in today.`}</strong></p>
    <p><strong parentName="p">{`*`}{`This Saturday is our Granite Games Fall Throwdown!  Come out and
cheer on the teams competing and check out our Vendors!  First heat
starts at 9:00am.  Reminder: There is a 5k run downtown so you may have
to park off Market or Washington street and walk over between the hours
of 8-10:30am.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      